/* App.css */
.full-screen-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.full-screen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 41%; /* Adjust position for smaller screens */
  left: 69%; /* Adjust position for smaller screens */
  background-color: transparent;
  line-height: 1em;
  max-width: 28%; /* Adjust max-width for smaller screens */
  box-sizing: border-box;
}

.problem {
  color: #1E1D24;
  font-size: 32px; /* Adjust font size for smaller screens */
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
}

.telefon {
  margin: 10px 0; /* Adjust margin for smaller screens */
  color: #04B3E2;
  padding: 0;
  font-size: 30px; /* Adjust font size for smaller screens */
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

.replacement-logo {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

@media screen and (max-width: 768px) {
  .text-overlay {
    top: 38%;
    left: 5%;
    max-width: 90%;
  }


  .problem {
    font-size: 22px;
  }

  .telefon {
    font-size: 25px;
    color: blue;
    margin: 5px 0;
  }
  .full-screen-container {
    height: 40vh;

  }

}
/* Add this CSS to your stylesheet */
/* Common styles */
.containeri {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap on smaller screens */
  align-items: center;
  justify-content: space-between;
  max-width: 1800px;
  padding: 20px;
  margin-top: 60px;
}

.text-containeri {
  flex: 1;
  margin-left: 6%;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.p {
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  color: #333;
}
.pt {
  color: #1e1d24;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
}
.photos-container {
  display: flex;
  align-items: center;
}

.photo {
  width: 400px;
  height: 200px;
  margin-left: 10px;
}



/* cfar bejm ne */
.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

}

.services h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-bottom: 50px;
  text-align: left;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.service {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #908e9b4f;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}


.service h3 {
  overflow-y: hidden;
  color: #1e1d24;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 500;

}
.service span {
  text-decoration: none;
  color: #818a91;
  overflow-y: hidden;
  font-size: 69px;
  font-weight: 500;
}

.service p {
  overflow-y: hidden;
  text-align: center;
  color: #79787F;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.643em;
}
.service .link {
  width: 160px;
  font-size: 19px;
  height: 35px;
  border-radius: 3px;
  color: white;
  text-decoration: none;
  padding-top: 15px;
  font-family: sans-serif;
  background-color: #30ccf6;
}

/* cmime */
.services1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;

}

.services1 h2 {
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
  text-align: left;
}

.services-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.service1 {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #908e9b4f;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.servicediv{
  margin: 55px 35px;
}

.service1 h3 {
  overflow-y: hidden;
  color: #1e1d24b0;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: 600;

}
.service1 h2 {
  overflow-y: hidden;
  color: #1E1D24;
  float: right;
  margin: 0;
  padding-top: 19px;

}
.service1 span {
  text-decoration: none;
  color: #818a91;
  overflow-y: hidden;
  font-size: 69px;
  font-weight: 500;
  float: left;
}

.service1 p {
  overflow-y: hidden;
  text-align: center;
  color: #79787F;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.643em;
  margin-bottom: 0px;
}
.service1 button {
  width: 150px;
  font-size: 18px;
  border: none;
  height: 50px;
  border-radius: 5px;
  color: white;
  font-family: sans-serif;
  background-color: #04B3E2;
}


/* firmattt */
.coin{
  display: flex;
  font-weight: bold;
  width: auto;
  height: auto;
  overflow-y: hidden;
  display: inline-block;
  margin: 0 30px;
  float: left;
  transition: all .2s ease-out;
} 
.coin img{
  position: relative;
  top: 6px;
  right: 3px;
  height: 50px;
  width: 153px;
}
.coinAnim{
  text-align: center;
  height: 188px;
  width: 83%;
  margin-left: 12%;
    overflow: hidden;
  box-sizing: border-box;
  position: relative;
} 
.coinAnim h3{
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
}
.coinAnim-inner{
  display: block;
  width: 200%;
  margin: 51px 0;
  position: absolute;
  animation: coinAnim 10s linear infinite;
} 

@keyframes coinAnim{
  0% {left: 0;}
  100% {left: -100%;}
}
/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .containeri {
    margin-left: 0px;
    align-items: center;
    padding: 8px; /* Adjust padding for smaller screens */
    margin-top: 30px; /* Adjust margin for smaller screens */
  }
  .p{
    font-size: 19px;
  }
  .photos-container {
    flex-direction: row-reverse;
    align-items: center;
  }

  .photo {
    margin-left: 0px;
    margin-top: 10px;
  }
  .text-containeri{
    margin-left: 0%;
   
    padding: 0px;
  }
  .services{
    padding: 10px;
  }
  .service {
    width: 95%;
  }
  .service1 {
    width: 95%;
  }
}


@media screen and (max-width: 1200px) {
  .containeri {
    margin-left: 0px;
    align-items: center;
    padding: 8px; /* Adjust padding for smaller screens */
    margin-top: 30px; /* Adjust margin for smaller screens */
  }
  .p{
    font-size: 19px;
  }
  .photos-container {
    flex-direction: row-reverse;
    align-items: center;
  }

  .photo {
    margin-left: 0px;
    margin-top: 10px;
  }
  .text-containeri{
    margin-left: 0%;
   
    padding: 0px;
  }
  .services{
    padding: 10px;
  }
  .service {
    width: 95%;
  }
  .service1 {
    width: 95%;
  }
}

/* fooooterr */

/* Footer container */
.footer {
  background-color: #968e8e2e;
  color: #1E1D24;
  padding: 20px 0;
  margin-top: 80px;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

/* Left footer content */
.l-footer {
  flex: 1;
  padding: 0 5%;
}

.l-footer h1 img {
  max-width: 100%;
}

/* Right footer content */
.r-footer {
  flex: 1;
  padding: 0 5%;
}

.r-footer h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.box {
  list-style: none;
  padding-left: 0;
}

.box li {
  margin-bottom: 8px;
}

.box a {
  color: #1E1D24;
  text-decoration: none;
  font-size: 14px;
}

/* Bottom footer content */
.b-footer {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  background-color: #968e8e2e;
}

.b-footer p {
  font-size: 14px;
  line-height: 1.5;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .l-footer, .r-footer {
      flex: 100%;
  }
}