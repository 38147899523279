
.div-container {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  justify-content: space-between; 
  margin:5% 5% 5% 3%; ;
}

.card {
  --main-color: #000;
  border: 2px solid lightblue;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 20px;
  width: 28%;
  margin-bottom: 20px; /* Add margin between each card */
  background: var(--bg-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card__back svg,
.card__menu svg {
  width: 20px;
  height: 20px;
}

.card__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card__img {
  width: 100%;
}

.reklama1 {
  width: 100%;
}

.card__img svg {
  height: 150px;
  transform: rotate(-45deg) translate(15px, 0px);
}

.card__title {
  font-weight: 600;
  font-size: 28px;
  color: var(--main-color);
}

.card__subtitle {
  font-size: 16px;
  color: #012a4a;
  font-family: "Poppins", sans-serif;
}

.card__price {

  font-size: 20px;
  color: var(--main-color);
  font-family: "Inter", sans-serif;
}

.card__counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 5px;
  background: #f7f7f7;
  border-radius: 50px;
}

.card__counter-score,
.card__btn {
  font-weight: 700;
  font-size: 22px;
  color: var(--main-color);
}

.card__btn {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: none;
  background: transparent;
}

.card__btn-plus {
  background: var(--bg-color);
}
.buttonthejr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: none;
  color: greenyellow;
}
.vija{
  margin-top: 10px;
  color: black;
}




section.card1 {
  --main-color: #000;
  border: 2px solid lightblue;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 20px;
  width: 28%;
  margin-bottom: 20px; /* Add margin between each card */
  background: var(--bg-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
section.card2 {
  --main-color: #000;
  border: 2px solid lightblue;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 20px;
  width: 28%;
  margin-bottom: 20px; /* Add margin between each card */
  background: var(--bg-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  perspective: 1000px;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.card__content1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  --main-color: #000;
  border: 2px solid lightblue;
  background-color: white;
  box-sizing: border-box;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  font-family: "Poppins", sans-serif;
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275); /* Separate transform for transition */
}

.card1:hover .card__content1 {
  transform: rotateX(0deg);
}

.card__title1 {
  margin: 0;
  padding-left: 5px;
  font-size: 24px;
  color: var(--white);
  font-weight: 700;
}

.card1:hover svg {
  scale: 0;
}

.card__description1 {
  margin: 10px 0 0;
  font-size: 14px;
  color: #000;
  line-height: 1.4;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
  padding: 10px;
  margin-left: 10px; /* Adjust margin as needed */
  box-sizing: border-box;
}
.emri{
  width: 105px;
}
.emri1{
  width: 140px;
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
  .card1:hover {
  box-shadow: 0 8px 16px #000000;
  }
.paragrafi {
  font-size: 16px;
  color: black;
  font-family: ui-serif;
  line-height: 3px;
  margin-bottom: 15px;
}
.paragrafi1 {
  font-size: 12px;
  color: #000;
  font-family: sans-serif;
  line-height: 3px;
  margin-bottom: 15px;
}
.iconat {
  width: 63px;
  height: auto;
  padding: 7px;
}
.porosia {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86%;
  margin: 0px auto;
  float: bottom;
}
.porosit {
  flex: 1;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.thejr {
  margin-left: 20px; /* Adjust margin as needed */
}

.custom-wrapper {
  display: flex;
  justify-content: space-between;
}

.custom-left {
  flex: 2 1;
  text-align: left;
  margin-bottom: 8px;
}

.custom-right {
  flex: 1;
  text-align: right;
}

.button2 {
  font-family: inherit;
  font-size: 18px;
  background: linear-gradient(to bottom, #4dc7d9 0%,#66a6ff 100%);
  color: white;
  padding: 0.8em 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.button2:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.button2:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

button2 span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.3s;
}

.button svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}

.button2 .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 0.5em;
  transition: all 0.3s;
}

.button2:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}

.button2:hover svg {
  transform: rotate(45deg);
}



.card1 {
  display: block; /* Display all sections initially */
}

.buttons-container {
  margin-bottom: 20px;
  margin-left: 7%;
  margin-top: 3%;
}

.buttons-container button {
  margin-right: 10px;
}

.card1:not(.active) {
  display: none;
  background-color: #000;
}
.zgjidh{
  padding: 1.3em 3em;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #0eb3e6;
  background-color: #fff;
  border: 1px solid #68bcda;
  font-family: "Poppins", sans-serif;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  font-weight: bold;
  outline: none;
}


.zgjidh:hover {
  background-color: #11657a;
  box-shadow: 0px 15px 20px rgba(9, 70, 110, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
.zgjidh:active,
.zgjidh:focus {
  background-color: #11657a !important;
  color: #fff !important;
  box-shadow: 0px 15px 20px rgba(58, 93, 207, 0.4) !important;
}


.buttons-container button:active {
  background-color: #11657a;}



/* Media Queries */
@media (max-width: 768px) {
  .div-container {
    flex-direction: column;
    align-items: center;
  }

  section.card1 {
    width: 90%;
    margin-bottom: 20px;
  }
  .emri1{
    width: 90px;
  }
  .emri{
    width: 90px;
  }
  .card__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .card__title {
    font-weight: 600;
    font-size: 18px;
    color: var(--main-color);
    font-family: 'Montserrat', sans-serif;
  }
  
  .card__subtitle {
    font-size: 15px;
    color: #012a4a;
    font-family: "Poppins", sans-serif;
  }
  
  .card__price {
  
    font-size: 19px;
    font-family: auto;

  }
  
  .card__counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 5px;
    background: #f7f7f7;
    border-radius: 50px;
  }
  
  .card__counter-score,
  .card__btn {
    font-weight: 700;
    font-size: 22px;
    color: var(--main-color);
  }
  
  .card__btn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    background: transparent;
  }
  
  .card__btn-plus {
    background: var(--bg-color);
  }
  .buttonthejr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: none;
    color: greenyellow;
  }
  .vija{
    margin-top: 10px;
    color: black;
  }
  .buttons-container {
    margin-left: 5%; /* Adjust the left margin for smaller screens */
  }

  .zgjidh {
    width: 45%;
    margin: 2%;
    padding: 1em 2.5em; /* Adjust padding for smaller screens */
    font-size: 13px; /* Adjust font size for smaller screens */
  }
  .button2{
    font-size: 12px
  }
  .button2 span{
    font-size: 15px
  }
  .paragrafi{
    font-size: 11px;
    line-height: 0px;
  }
  .paragrafi1{
    font-size: 9px;
    line-height: 0px;
  }
  .porosia{
    width: 90%;
  }
  .porosit{
    font-size: 18px;
  }
  .right h2{
    font-size: 18px;
  }
  
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .paragrafi{
    font-size: 12px;
    line-height: 0px;
  }
  .paragrafi1{
    font-size: 10px;
    line-height: 0px;
  }
  .card__price{
    font-size: 17px;
  }
  .card__title{
    font-size: 20px;
  }
  .porosit{
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .paragrafi1{
    font-size: 9px;
    line-height: 0px;
  }
  .card__price{
    font-size: 17px;
  }
}



@media (max-width: 500px) {
  .card__price {
  
    font-size: 16px;
    font-family: auto;

  }
  
}