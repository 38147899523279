/* General styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
    color: #333;
  }
  
  
  /* Section headings */
  .mbrenda {
    color: rgb(6, 5, 5, 1);
    font-size: 27px;
    margin-left: 200px;
    font-family: 'Montserrat', sans-serif;
}
  
  /* Image gallery container */
  .gallery-container,
  .gallery-container1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 67%;
    margin-left: 200px;
    justify-items: center;
    margin-top: 20px;
  }
  
  /* Individual gallery images */
  .gallery-container img,
  .gallery-container1 img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  

  /* Responsive adjustments */
  @media screen and (max-width: 1200px) {
    .container {
      padding: 10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .container {
      border-radius: 0;
      box-shadow: none;
    }
    .mbrenda{
        margin: 0px;
    }
    .gallery-container,
    .gallery-container1 {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      width: 100%;
      margin: 0px;
    }
  }
/* fooooterr */

/* Footer container */
.footer {
    background-color: #968e8e2e;
    color: #1E1D24;
    padding: 20px 0;
    margin-top: 80px;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  /* Left footer content */
  .l-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .l-footer h1 img {
    max-width: 100%;
  }
  
  /* Right footer content */
  .r-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .r-footer h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .box {
    list-style: none;
    padding-left: 0;
  }
  
  .box li {
    margin-bottom: 8px;
  }
  
  .box a {
    color: #1E1D24;
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Bottom footer content */
  .b-footer {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: #968e8e2e;
  }
  
  .b-footer p {
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .l-footer, .r-footer {
        flex: 100%;
    }
  }