
body, h1, img {
    margin: 0;
    padding: 0;
    
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  .container1 {
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    width: 550px;
  }
  
  .images1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 67%;
    margin-left: 200px;
    justify-items: center;
    margin-top: 20px;
    
  }
  
  .servis55 {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  .servis555 {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;

  }
  
  .servis55:hover {
    transform: scale(1.05);
  }

  /* pjesa e 2 */
  .container11 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .images11 {
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    gap: 10px;
    justify-content: center;
    
  }
  
  .servis13 {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
  }
  .servis14 {
    max-width: 33%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;

  }
  
  .servis55:hover {
    transform: scale(1.05);
  }
/* Media query for phones */
@media screen and (max-width: 768px) {
  .container1, .container11 {
    padding: 10px;
    border-radius: 0;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .images1, .images11 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .servis555, .servis13, .servis14 {
    max-width: 100%;
  }
}

  /* Footer container */
.footer {
    background-color: #968e8e2e;
    color: #1E1D24;
    padding: 20px 0;
    margin-top: 80px;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  /* Left footer content */
  .l-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .l-footer h1 img {
    max-width: 100%;
  }
  
  /* Right footer content */
  .r-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .r-footer h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .box {
    list-style: none;
    padding-left: 0;
    font-size: 18px;
  }
  
  .box li {
    margin-bottom: 8px;
  }
  
  .box a {
    color: #1E1D24;
    text-decoration: none;
    font-size: 18px;
  }
  
  /* Bottom footer content */
  .b-footer {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: #968e8e2e;
  }
  
  .b-footer p {
    font-size: 17px;
    line-height: 1.5;
  }
.pldh{
  list-style: none;
}
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .l-footer, .r-footer {
        flex: 100%;
    }
  }
/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .container1, .container11 {
    padding: 10px;
    border-radius: 0;
  }

  h1 {
    width: 100%;
  }

  .images1, .images11 {
    justify-content: flex-start;
  }

  .servis555, .servis13, .servis14 {
    max-width: 100%;
  }

  .l-footer, .r-footer {
    flex: 100%;
  }
}
