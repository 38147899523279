/* Slideshow container */
.slideshow {
    position: relative;
    margin: auto;
    overflow: hidden;
    margin-top: 3%;
  }
  .foto1{
    width: 100%;
  }
  /* Slide */
  .slide {
    display: none;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
  }
  
  /* Show the active slide */
  .slide.active {
    display: block;
    opacity: 1;
  }
  
  /* Slide image */
  .slide img {
    width: 100%;
    height: auto;
  }
  
  /* Slideshow navigation buttons */
  .prev, .next {
    position: absolute;
    top: 50%;
    font-size: 50px;
    transform: translateY(-50%);
    padding: 15px;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    transition: background-color  0.3s ease;
    z-index: 1;
  }
  
  /* Position the previous button */
  .prev {
    left: 15px;
  }
  
  /* Position the next button */
  .next {
    right: 15px;
  }
  
  /* Hover effect for navigation buttons */
  .prev svg:hover, .next:hover {
    box-shadow: black;
  }
  
  /* Optional: Button styles */
  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  