.header {
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
}

.logonavbar {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  border: none;
  height: 170px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
}

.navbar a {
  position: relative;
  font-size: 25px;
  display: inline-block;
  padding: 0px 20px 0px 20px;
  color: black;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

.navbar li {
  list-style: none;
}

.navbar a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.home{
  margin-left: 300px;
}

.navbar a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.hide{
  visibility: hidden;
}


@media screen and (max-width: 768px) {
  .header {
    flex-direction: column; 
    align-items: center; 
    padding-bottom: 20px;
    width: 97%;
  }

  .navbar {
    display: table-row;
    margin-top: 20px; /* Add space between logo and navbar */
    width: 100%; /* Full width for small screens */
    justify-content: center; /* Align items to the center */
  }

  .home {
    margin-left: 0; /* Reset margin on small screens */
    margin-top: 10px; /* Add space between logo and "Home" */
  }

  .navbar a {
    font-size: 24px; /* Adjust font size for small screens */
    padding: 10px;
  }
  .logo{
    width: 300px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .logonavbar {
    height: 155px;
  }}