
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  max-width: 900px;
}

.contact-container form {
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.contact-container img {
  width: 100%;
  max-width: 400px;
  margin: 30px 0;
}

.contact-container h2 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  color: #262626;
}

.contact-container .form-control {
  margin-bottom: 25px;
}

.contact-container .form-control label {
  font-size: 18px;
  font-weight: 500;
  color: #262626;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
  display: block;
}

.contact-container .form-control input,
.contact-container .form-control textarea {
  width: 91%;
  padding: 12px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
}

.contact-container .form-control input:focus,
.contact-container .form-control textarea:focus {
  outline: none;
  border-color: #f73535;
}

.contact-container .form-control textarea {
  height: 150px;
}

.contact-container button[type="submit"] {
  background-color: #367adb;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-container button[type="submit"]:hover {
  background-color: #ff0000;
}
/* Media queries for responsive layout */
@media screen and (min-width: 600px) {
  .contact-container {
    flex-direction: row;
  }

  .contact-container form {
    width: 50%;
    padding: 0 30px;
    margin-right: 30px;
  }

  .contact-container img {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .contact-container img{
    visibility: hidden;
    display: contents;
    }
}

/* Footer container */
.footer {
  background-color: #968e8e2e;
  color: #1E1D24;
  padding: 20px 0;
  margin-top: 80px;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

/* Left footer content */
.l-footer {
  flex: 1;
  padding: 0 5%;
}

.l-footer h1 img {
  max-width: 100%;
}

/* Right footer content */
.r-footer {
  flex: 1;
  padding: 0 5%;
}

.r-footer h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.box {
  list-style: none;
  padding-left: 0;
}

.box li {
  margin-bottom: 8px;
}

.box a {
  color: #1E1D24;
  text-decoration: none;
  font-size: 14px;
}

/* Bottom footer content */
.b-footer {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  background-color: #968e8e2e;
}

.b-footer p {
  font-size: 14px;
  line-height: 1.5;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .l-footer, .r-footer {
      flex: 100%;
  }
}