/* Reset and general styles */
body, h1, p {
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  .contineri {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .contineri1 {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .cont {
    flex: 1;
  }
  
  .imag  {
    flex: 1;
    display: initial;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .imag .serv4 {
    max-width: 30%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease-in;
    margin-bottom: 10px;
  }
  .imag .serv5 {
    max-width: 60%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease-in;
    margin-bottom: 10px;
  }
  .imag img:hover {
    transform: scale(1.05);
  }
  
  @media screen and (max-width: 768px) {
    .contineri{
      flex-direction: column;
    }
  
    .cont,
    .imag {
      flex: 100%;
      align-items: flex-start;
      text-align: left;
    }
  }
  /* Footer container */
.footer {
    background-color: #968e8e2e;
    color: #1E1D24;
    padding: 20px 0;
    margin-top: 80px;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  /* Left footer content */
  .l-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .l-footer h1 img {
    max-width: 100%;
  }
  
  /* Right footer content */
  .r-footer {
    flex: 1;
    padding: 0 5%;
  }
  
  .r-footer h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .box {
    list-style: none;
    padding-left: 0;
  }
  
  .box li {
    margin-bottom: 8px;
  }
  
  .box a {
    color: #1E1D24;
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Bottom footer content */
  .b-footer {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: #968e8e2e;
  }
  
  .b-footer p {
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .l-footer, .r-footer {
        flex: 100%;
    }
  }